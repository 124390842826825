<template>
  <v-container fluid fill-height class="d-flex justify-start align-center flex-column pa-0">
    <div>
      <v-row dense>
        <v-col cols="12" class="text-center">
          <!-- <img :src="require('@/assets/to_logo.png')" width="320" /> -->
          <migration-logo/>
        </v-col>
      </v-row>
      <v-row dense class="mt-1 mb-0">
        <v-col cols="12" class="text-center">
          <h2 :class="$style.title">{{ header }}</h2>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col cols="12" class="d-flex justify-center">
          <v-card color="#fff" class="py-1 pa-5 mb-3 rounded-xl " rounded :class="$style.form">
            <v-card-text :class="$style.form_text" :style="{ margin: '32px 0px', }">
              <h2 :class="$style.form_msg">
                <!-- JRE IDへの移行手続きが<br>完了しました -->
                JRE IDへの<br>
                切替手続きが完了しました。
              </h2>
              <br />
              <h2 :class="$style.form_msg" :style="{ margin: '0 -29px' }">
                <!-- これまでご利用いただいて<br/>いたJaM id のサービスは<br/>JRE IDで引き続きご利用<br/>いただけます -->
                これまでご利用いただいた<br>
                JaM idのサービスは<br>
                引き続きJRE IDでご利用いただけます。<br>
                <br />
                引き続き各種サービスご利用の場合<br>
                現在のページを一旦閉じ、各サービスサイトから<br>
                ログイン手続きを行ってください。
              </h2>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>


<script>
export default {
  data() {
    return {
      header: 'JRE IDへの切替手続き完了',
      mainContent: ''
    };
  },
  methods: {
    // onRedirect() {
    //   window.location.href = "http://localhost:7080/entrypoint";
    // }
  },
}
</script>

<style module lang="scss">
@import "@/styles/common.scss";

.title {
  font-size: 16px;
  color: #6f808c;
  font-weight: 600;
}

.form_msg {
  // font-size: 16px !important;
  font-size: 13px !important;
  color: #373e42;
  font-weight: 600;
  text-align: center !important;
  line-height: 20px;
}


.form {
  background-color: #ffffff;
  width: 100%;
  min-width: 320px;
  max-width: 320px;
  padding: 15px 30px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05) !important;
}
</style>
